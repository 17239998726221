 
export default async function(url){
    const resp = await fetch(url,
        {headers: {  "accept": "application/json"}}
    )
    if (!resp.ok) {
 
        throw new Error(`error retreiving ${url}`)
    }
    return await resp.json()
}