


import $ from "jquery";

const searchModal = document.querySelector("#search-modal");


if(searchModal){

    const form = searchModal.querySelector("form");


    $(searchModal).on("show.bs.modal", e => {
        form.querySelector(".btn-primary").textContent = "Search";
        form.reset();
        
    } );

    $(searchModal).on("shown.bs.modal", e=> {
        setTimeout(() => searchModal.querySelector("#search-site-input").focus());
    })

    form.addEventListener("submit", e=>{
        const searchString = searchModal.querySelector("#search-site-input").value;
 
        if (searchString){
            const searchURL = new URL(document.querySelector("input#searchResultsPage").value);
            searchURL.searchParams.set("s", searchString);

            form.querySelector(".btn-primary").textContent = "Loading...";
            
            window.location.href = searchURL.toString();
        }
    })
    
}
