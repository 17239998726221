


window.addEventListener('DOMContentLoaded', () => {

    const panel = document.querySelector("#content-panel.expanded.partially-expanded");

    if (panel){

        const shrinker = panel.querySelector(".shrinker")
        const canCollapse = shrinker.offsetHeight < shrinker.scrollHeight;

        if (!canCollapse){
            panel.classList.remove("expanded");
            panel.classList.remove("partially-expanded");
            panel.classList.add("always-expanded");
            return;
        }

        
        for (const button of panel.querySelectorAll(".show-less")){
            button.addEventListener("click", event => {
                event.preventDefault();
                panel.classList.add("partially-expanded");
                setTimeout( () => {
                    const top = document.querySelector("#content-panel").getBoundingClientRect().top;
                    
                    if (top < 0){
                        const margin = 10; // pixels

                        window.scrollTo(0, window.scrollY + top - margin);
                    }

                }
                )
            });
        }

        for (const button of panel.querySelectorAll(".show-more")){

            button.addEventListener("click", event => {
                event.preventDefault();

                panel.classList.remove("partially-expanded");
                
            });
        }
    }


});

