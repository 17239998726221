import $  from "jquery";

function gtag() {
    window.dataLayer.push(arguments);
}
  
const BF_GA_EVENTS = {
    'BF_PHONE': {
      'event':'Click',
      'event_category':'Links',
      'event_label':'Phone Number',
    },
    'BF_EMAIL_ADDRESS': {
      'event':'Click',
      'event_category':'Links',
      'event_label':'Email Address',
    },
    'BF_INSTAGRAM': {
      'event':'Click',
      'event_category':'Links',
      'event_label':'Instagram',
    },
    'BF_YOUTUBE': {
      'event':'Click',
      'event_category':'Links',
      'event_label':'YouTube',
    },
    'BF_GIFTS': {
      'event':'Click',
      'event_category':'Links',
      'event_label':'Barnabas Gifts Download',
    },
    'BF_EMAIL_SIGNUP': {
      'event':'Click',
      'event_category':'Links',
      'event_label':'Email Signup',
    },
    'BF_FUNDRAISE': {
      'event':'Click',
      'event_category':'Links',
      'event_label':'Fundraise',
    },
    'BF_QUESTION_SIGNUP': {
      'event':'Click',
      'event_category':'Links',
      'event_label':'Question Signup',
    },
    'BF_VIDEO': {
      'event':'Click',
      'event_category':'Links',
      'event_label':'Video Click',
    },
    'BF_JOB_APPLICATION': {
      'event':'Click',
      'event_category':'Links',
      'event_label':'Job Application Download',
    },
    'BF_ADD_TO_CART': {
      'event':'Click',
      'event_category':'Links',
      'event_label':'Add to Cart',
    },
    'BF_GIFTS_PDF': {
      'event':'Click',
      'event_category':'Links',
      'event_label':'Barnabas Gifts PDF Download',
    }
  }

  
  function setupEvents() {
    $( ".js-bfGaEvent" ).on( "click", function() {
      const eClasses = $(this).attr("class").split(/\s+/);
      let gaEvent = false
      eClasses.forEach(function(item) { 
        if(BF_GA_EVENTS.hasOwnProperty(item)) {
          gaEvent =  BF_GA_EVENTS[item]
        }
      })
      if(gaEvent) {
        gtag('event',gaEvent.event,{
          'event_category':gaEvent.event_category,
          'event_label':gaEvent.event_label
      })
      }
    });
  }
  
if( window.dataLayer){
    setupEvents();
} 
  