const latest = document.querySelector("div.latest");
import Latest from "./components/Latest.svelte";
import SectionInfo from "./models/SectionInfo";
interface LatestParams {
  limit: number;
  section: string;
  cardClass: string;
  hideDate: boolean;
  sections: { [name: string]: SectionInfo };
}
if (latest) {
  const params: LatestParams | null = JSON.parse(
    latest.getAttribute("data-params") || "null"
  );
  if (params) {
    new Latest({ target: latest, props: params });
  }
}
