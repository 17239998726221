<script lang="ts">
  import { formatDate } from "../../date";
  export let page: PageInfo;
  export let showSectionBadge = false;
  export let sections: { [name: string]: SectionInfo } = {};
  export let cardClass = "";
  export let showDescription = false;
  export let hideDate = false;
  export let hideImage = false;
  export let verticalCard = false;
  import PageInfo from "../../models/PageInfo";
  import SectionInfo from "../../models/SectionInfo";
  import Figure from "./Figure.svelte";
  import Title from "./Title.svelte";
  let sectionObj: SectionInfo | null;
  $: sectionObj =
    (showSectionBadge && page.section && sections && sections[page.section]) ||
    null;
</script>
{#if verticalCard}

<div class="card {cardClass}">
  <div class="row">
    <div class="col-6">
      {#if !hideImage}
      {#if showSectionBadge}
        <a href={page.uri}>
          <Figure {page} />
        </a>
      {:else}
        <Figure {page} />
      {/if}
    {/if}    </div>
    <div class="col-6">
      <div class="content">
        {#if showSectionBadge}
        <a href={page.uri}>
          <Title {page} />
        </a>
      {:else}
        <Title {page} />
      {/if}
      {#if !hideDate && page.date}
        <span class="card-date">
          {formatDate(page.date)}
        </span>
      {/if}
      </div>
    </div>

  </div>
</div>

{:else}
<div class="card {cardClass}" style="width: 100%">
  {#if !hideImage}
  {#if showSectionBadge}
    <a href={page.uri}>
      <Figure {page} />
    </a>
  {:else}
    <Figure {page} />
  {/if}
{/if}
  <div class="content">
    {#if showSectionBadge}
      <a href={page.uri}>
        <Title {page} />
      </a>
    {:else}
      <Title {page} />
    {/if}

    <div class="d-flex justify-content-between align-items-center">
      {#if !hideDate && page.date}
        <span class="card-date">
          {formatDate(page.date)}
        </span>
      {/if}
      {#if sectionObj}
        <a
          class="badge text-white"
          style="background: {sectionObj.color};"
          href={sectionObj.uri}>{sectionObj.title}</a
        >
      {/if}
    </div>
    {#if showDescription}
      {#if page.description}
        <span class="card-description">
          {page.description}
        </span>
      {/if}
    {/if}
  </div>
</div>

{/if}