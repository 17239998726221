

if ("IntersectionObserver" in window) {

    const lazyImageObserver = new IntersectionObserver(  entries => {
        [].slice.call(entries).forEach(entry => {

            const setScrolling = () => {
                const rect = entry.target.getBoundingClientRect();
                if (rect.top < 0 && rect.bottom > window.innerHeight ){
                    entry.target.classList.add("during");
                } else {
                    entry.target.classList.remove("during");
                }

                if (rect.bottom < window.innerHeight){
                    entry.target.classList.add("after");
                } else {
                    entry.target.classList.remove("after");
                }

            }

            setScrolling();

            if (entry.isIntersecting) {
                window.addEventListener("scroll", setScrolling);
            } else {
                window.removeEventListener("scroll", setScrolling);
            }
        });
    });


    
    [].slice.call( document.querySelectorAll(".scrolling-background-image") ).forEach(div => {
        lazyImageObserver.observe(div);


    });

} 

