    


function setup(){
    console.log("setting up");

    [].slice.call(document.querySelectorAll("div[data-campaign-progress]")).forEach( async div => {

        const pageURL = div.dataset.campaignProgress;
    
        const unitCost = div.dataset.unitCost;

        if (!pageURL){
            return;
        }

        const response = await fetch(pageURL + "/goal");

        const {campaignGoal} = await response.json();

        const {expectedAmount, raisedAmount, percentComplete} = campaignGoal;

        const impact = unitCost ? parseInt( (Number(raisedAmount) * 1.0) / (Number(unitCost) * 1.0) ) : undefined;

        if (!expectedAmount){
            console.error("Unrecognized response for " + pageURL + "/goal")
            return;
        }

        div.classList.remove("invisible");

        div.querySelector(".progress-bar").setAttribute("style", `width: ${percentComplete}%; background:#5091cd;`)

        div.querySelector(".progress-bar").setAttribute("aria-valuenow", "" + percentComplete);

        div.querySelector(".campaignProgress__raised-amount").innerHTML = "$" + raisedAmount.toFixed(2);

        div.querySelector(".campaignProgress__goal").innerHTML = "$" + expectedAmount.toFixed(2) + " goal";

        if (impact !== undefined){
            div.querySelector(".campaignProgress__impact-amount").innerHTML = impact;
            div.querySelector(".campaignProgress__impact").classList.remove("d-none")
        }

    })


}

if (document.readyState === "loading") {
    window.addEventListener("DOMContentLoaded", setup);
} else {
    setTimeout(setup);
}

