import LazyLoad from "vanilla-lazyload";

new LazyLoad();

import "bootstrap";
import "./panel";
import "./locate";
//import "./scaaw-modal";
import "./supporters-event";
import "./search-modal";
import "./filter-panel";
import "./ga-events";
//import "./setup-mailchimp";
import "./campaign-progress";
import "./scrolling-background-image";
import "./media-gallery-modal";
import "./latest";
import AOS from "aos";

AOS.init(); 

console.log('result')
if ("serviceWorker" in navigator && document.querySelector("input#sw_js")){
    window.addEventListener('load', () => {
        navigator.serviceWorker.register((document.querySelector("input#sw_js") as HTMLInputElement).value);
    });
}

  





