



window.addEventListener("DOMContentLoaded", ()=>{
    if (!document.querySelectorAll(".supporters-event").length){
        return;
    }

    const region = document.querySelector("body").dataset.region;

    const eventStart = {
        "nz": new Date("2020-07-23T19:00:00.000+12:00")
    }[region];

    const eventFinish = {
        "nz": new Date("2020-07-23T21:00:00.000+12:00")
    }[region];

    if (eventStart){

        updateEventStatus = () => {
            const eventHasStarted = new Date().toISOString() > eventStart.toISOString();

            const eventHasFinished = new Date().toISOString() > eventFinish.toISOString();
    
            status = eventHasStarted ? ( eventHasFinished ? "finished" : "started")  : "not-started" ;

            document.body.dataset.supportersEventStatus = status;

            if (status === "finished" && window.eventStatusUpdater){
                clearInterval(window.eventStatusUpdater);
            }
        }

        updateEventStatus();

        window.eventStatusUpdater = setInterval(updateEventStatus, 1000 * 60);
    }

})




