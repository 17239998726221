
import $ from "jquery";
import navigateToTranslation from "./navigateToTranslation.js";
import { setCookie, getCookie } from "./util.js"
import { getCountryFromTimezone } from "./lib/timezone/timezone";

const years = 5
const days = 365

const expire = days * years

const modal = document.querySelector('.js-region-modal')

// Show the coutntry select modal on click of the selector button
const selectors =  [...document.querySelectorAll('.js-language-footer')]
for (const selector of selectors) {
  selector.addEventListener('click', (e)=>{
    e.preventDefault()
    $(modal).modal("show")
  })
}

// Set the barnabasfund-org-country cookie and reload the page when the country flag is clicked
const pickers = [...document.querySelectorAll('.js-language-picker')]
for (const picker of pickers) {
  picker.addEventListener('click', (e)=>{
    e.preventDefault()
    console.log("setting barnabasfund-org-country cookie")
    setCookie("barnabasfund-org-country", picker.dataset.country.toUpperCase(), expire)
    navigateToTranslation(picker.dataset.country)
  })
}

// Set a flag in local storage saying region selector hidden, so we dont prompt the user again
$(modal).on('hide.bs.modal', ()=> {
  window.localStorage.countryModalHidden = "true"
})
 

// Has the user chosen a country?
let currentCountry = (getCookie("barnabasfund-org-country")||"").toLowerCase() || getCountryFromTimezone();

const allowedCountries = JSON.parse(document.head.querySelector("meta[name=siteLanguages]").content)
// Open the country select modal for users outside a recognized country
if (!allowedCountries.includes(currentCountry) &&  window.localStorage.countryModalHidden !== "true"){
  $(modal).modal('show')
}
 