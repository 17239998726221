<script lang="ts">
  import CardImage from "./Image.svelte";
  import PageInfo from "../../models/PageInfo";

  export let page: PageInfo;
</script>

<figure class="card-img">
  <CardImage src={page.imageCard} alt={page.title} />
</figure>
