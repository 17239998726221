
import $ from "jquery";


window.addEventListener("DOMContentLoaded", () => {
    const modal = document.querySelector("#media-gallery-modal");
    if(!modal){
        return;
    }
    const captionElement = modal.querySelector("#media-gallery-caption");
    const loadingImage = modal.querySelector("#media-gallery-image").src;
    const indexElement = modal.querySelector("#media-gallery-image-id");
    const thumbnails = [].slice.call(document.querySelectorAll(".media-gallery-thumnbail"));

    let currentImageId = 0;


    function setImage(id) {

        captionElement.textContent = "Loading...";
        currentImageId = id;
        modal.querySelector("#media-gallery-image").alt = "Loading...";
        indexElement.innerHTML = id !== null ? `${id + 1} / ${thumbnails.length}` : "Loading...";
        modal.dataset.caption = "Loading...";

        const thumbnail = thumbnails[id];

        modal.querySelector("#media-gallery-image-md").srcset = id !== null ? thumbnail.dataset.mediaGallerySrcMd : loadingImage;
        modal.querySelector("#media-gallery-image-sm").srcset = id !== null ? thumbnail.dataset.mediaGallerySrcSm : loadingImage;
        modal.querySelector("#media-gallery-image").src = id !== null ? thumbnail.dataset.mediaGallerySrc : loadingImage;

        if (id !== null){
            modal.dataset.caption = thumbnail.dataset.mediaGalleryCaption ? JSON.parse('"' + thumbnail.dataset.mediaGalleryCaption + '"') : "";
        }


        if(modal.querySelector("#media-gallery-image").complete){
            onLoad();
        }

    }

    function onLoad(){
        captionElement.textContent = modal.dataset.caption || "";
        modal.querySelector("#media-gallery-image").alt = modal.dataset.caption || "";
    }

    modal.querySelector("#media-gallery-image").addEventListener("load", onLoad);


    $(modal).on("hide.bs.modal", () => setImage(null));

    setImage(null);





    for (const thumnbail of thumbnails){
        thumnbail.addEventListener("click", e => {
            e.preventDefault();
            $(modal).modal("show");
            const index = thumbnails.indexOf(thumnbail);
            if (index===-1){
                throw new Error("Couldnt find image");
            }
            setImage(index);
        })
    };

    if (thumbnails.length){
        modal.querySelector("[data-media-gallery-action=next]").addEventListener("click", ()=>{
            setImage( (currentImageId + 1)%thumbnails.length )
        });

        modal.querySelector("[data-media-gallery-action=previous]").addEventListener("click", ()=>{
            setImage( (currentImageId - 1 + thumbnails.length)%thumbnails.length )
        });
    }



});
