

function filterCountries(filterPanel, filterString = "") {
    const termsList = document.querySelector("#terms-list");

    const countries = [...termsList.querySelectorAll(".card-container")];

    let foundOne = false;

    for (const country of countries) {

        if (filterString && country.dataset.title.toLowerCase().indexOf(filterString.toLowerCase()) === -1) {
            country.classList.add("d-none");
        } else {
            country.classList.remove("d-none");
            foundOne = true;
        }
    }

    if (foundOne) {
        termsList.querySelector("#no-filter-match").classList.add("d-none");
    } else {
        termsList.querySelector("#no-filter-match").classList.remove("d-none");
    }

    if (filterString) {
        const clearPanel = filterPanel.querySelector("#filter-clear-panel");
        clearPanel.classList.add("expanded");
        clearPanel.querySelector("#filter-text").innerText = filterString;
    } else {
        filterPanel.querySelector("#filter-clear-panel").classList.remove("expanded");
    }

}


window.addEventListener("DOMContentLoaded", () => {

    const filterPanel = document.querySelector("#filter-panel");


    if (filterPanel) {
        const form = filterPanel.querySelector("form");


        form.querySelector("input")
            .removeAttribute("disabled");

        form.querySelector("input")
            .setAttribute("placeholder", "Filter by name");

        form.querySelector(".btn")
            .removeAttribute("disabled");


        form.removeAttribute("disabled");

        form.addEventListener("submit", e => {

            const filterString = form.querySelector("input").value;

            filterCountries(filterPanel, filterString);

            return false;
        })

        filterPanel.querySelector("#filter-panel-close").addEventListener("click", event => {
            event.preventDefault();

            filterCountries(filterPanel);

            form.reset();

        });

        form.querySelector("input").addEventListener("keyup", event => {
            if (event.key === "Escape" || event.key === "Esc" || event.keyCode === 27) {

                filterCountries(filterPanel);

                form.reset();

            }
        });

    }



});