<script lang="ts">
  import PageInfo from "../../models/PageInfo";
  import SectionInfo from "../../models/SectionInfo";
  import Inner from "./Inner.svelte";

  export let page: PageInfo;
  export let showSectionBadge: boolean = false;
  export let sections: { [name: string]: SectionInfo } = {};
  export let cardClass: string = "";
  export let showDescription: boolean = false;
  export let hideDate: boolean = false;
  export let hideImage: boolean = false;
  export let verticalCard = false;
</script>

{#if !showSectionBadge}
  <a href={page.uri}>
    <Inner {page} {sections} {cardClass} {showDescription} {hideDate} {hideImage} {verticalCard} />
  </a>
{:else}
    <Inner {page} {sections} {cardClass} {showDescription} {hideDate} {hideImage} {verticalCard} />
{/if}
