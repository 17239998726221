<script lang="ts">
  import PageInfo from "../models/PageInfo";
  import Card from "./ArticleCard/ArticleCard.svelte";
  let pages: PageInfo[] = null;
  import { region } from "../globals";
  import get from "../get";
  export let cardClass: string;
  export let section: string;
  export let hideDate: boolean;
  export let hideImage: boolean;
  export let verticalCard: boolean;

  export let limit: number;

  async function load(): Promise<void> {
    try {
      const all = await get(`/${region}/${section}/latest.json`);
      pages = all.slice(0, limit);
    } catch (err) {
      console.error(
        `error latest articles in section ${section}: ` + err.message
      );
      return;
    }
  }

  load();
</script>

{#if pages && pages.length > 0}
  <div class="container align-item-center">
    <div class="row">
      <div class="col-sm-12 col-md-4">
        <Card page={pages[0]} {cardClass} {hideDate} {hideImage} />
      </div>
      <div class="col-sm-12 col-md-5 no-image">
        {#if pages && pages.length > 1}
          <Card
            page={pages[1]}
            cardClass="{cardClass}"
            {hideDate}
            {hideImage} verticalCard={true}
          />
        {/if}
        <hr class=""/>
        {#if pages && pages.length > 2}
          <Card page={pages[2]} {cardClass} {hideDate} {hideImage} verticalCard={true} />
        {/if}
      </div>
      
      <div class="col-sm-12 col-md-3 no-image">
        <hr class=""/>
        {#if pages && pages.length > 3}
          <Card page={pages[3]} {cardClass} {hideDate} hideImage={true} />
        {/if}
        <hr class=""/>
        {#if pages && pages.length > 4}
        <Card page={pages[4]} {cardClass} {hideDate} hideImage={true}  />
      {/if}
      </div>
    </div>
  </div>
{:else}
  <p>Loading articles...</p>
{/if}