import { region } from "./globals";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function formatDate(isoString: string): string {
  if (!isoString) {
    return "";
  }
  const d = new Date(isoString);
  const month = months[d.getMonth()];
  switch (region) {
    case "us":
      return `${month} ${d.getDate()} ${d.getFullYear()}`;
    default:
      return `${d.getDate()} ${month} ${d.getFullYear()}`;
  }
}
